import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import AddClassModal from './AddClassModal';
import { getAuth } from 'firebase/auth';
import { db } from './config/firebase-config';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import './styles/CatalogCard.css'; // Import the new CSS file
import './styles/SectionHeader.css'; // Import the new CSS module
import emptyStateIcon from './icons/classes.svg'; // Add this import
import filterIcon from './icons/filter-icon.svg';
import FilterModal from './components/FilterModal';
import { useNavigate } from 'react-router-dom';
import './styles/SegmentedControl.css';

const CatalogList = () => {
  const [classes, setClasses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeSegment, setActiveSegment] = useState('service'); // Default to 'service'
  const [showFilters, setShowFilters] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [sortOption, setSortOption] = useState('recent');
  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.filter-container')) {
        setShowFilters(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleShowModal = (classData = null) => {
    setSelectedClass(classData);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const saveClass = async (classData) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error('No user logged in!');
      return;
    }
  
    // Convert both cost and duration to integers
    const numericCost = parseInt(classData.cost, 10);
    const numericDuration = parseInt(classData.duration, 10);
    
    if (isNaN(numericCost)) {
      console.error('Invalid cost:', classData.cost);
      return;
    }

    if (classData.inventoryType === 'service' && isNaN(numericDuration)) {
      console.error('Invalid duration:', classData.duration);
      return;
    }
  
    try {
      if (classData.id) {
        // Updating existing class
        const classRef = doc(db, 'classes', classData.id);
        const updateData = {
          ...classData,
          cost: numericCost,
          duration: classData.inventoryType === 'service' ? numericDuration : null,
          updatedAt: serverTimestamp(),
          updatedBy: user.uid
        };
        await updateDoc(classRef, updateData);
      } else {
        // Creating new class
        const newClassData = {
          ...classData,
          cost: numericCost,
          duration: classData.inventoryType === 'service' ? numericDuration : null,
          createdAt: serverTimestamp(),
          createdBy: user.uid,
          updatedAt: serverTimestamp(),
          updatedBy: user.uid
        };
        await addDoc(collection(db, 'classes'), newClassData);
      }
      fetchClasses();
    } catch (error) {
      console.error('Error saving class:', error);
    }
    handleCloseModal();
  };
  


  const deleteClasses = async (classId) => {
    try {
        const classRef = doc(db, 'classes', classId);
        await deleteDoc(classRef);
        fetchClasses(); // Refresh the members list after deletion
    } catch (error) {
        console.error('Error deleting class:', error);
    }
};

  const fetchClasses = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('No user logged in!');
      return;
    }

    let baseQuery = collection(db, 'classes');
    let conditions = [where("createdBy", "==", user.uid) ];

    const q = query(baseQuery, ...conditions); 

    try {
      const querySnapshot = await getDocs(q);
      const classesData = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.class_name.localeCompare(b.class_name));
      setClasses(classesData);

      // Extract all unique tags
      const tags = new Set(classesData.flatMap(cls => cls.tags || []));
      setAllTags(Array.from(tags).map(tag => ({ value: tag, label: tag })));
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions || []);
  };

  const filteredByType = classes.filter(item => 
    item.inventoryType === activeSegment
  );

  const filteredClasses = filteredByType.filter(item => 
    selectedTags.length === 0 || 
    selectedTags.some(tag => item.tags && item.tags.includes(tag.value))
  );

  const getCadenceClass = (cadence) => {
    if (!cadence) return '';
    
    switch (cadence.toLowerCase()) {
      case 'mensual':
        return 'mensual';
      case 'semanal':
        return 'semanal';
      case 'diario':
        return 'diario';
      case 'evento':
        return 'evento';
      default:
        return '';
    }
  };

  const selectStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderRadius: '20px',
      backgroundColor: '#F4EDE4',
      border: '1.5px solid #1F1F1F',
      minHeight: '36px', // Match button height
      boxShadow: 'none',
      '&:hover': {
        border: '1.5px solid #1F1F1F',
      },
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: '#1F1F1F',
      borderRadius: '50px',
    }),
    multiValueLabel: (baseStyles) => ({
      ...baseStyles,
      color: '#F4EDE4',
      padding: '2px 8px',
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      color: '#F4EDE4',
      borderRadius: '0 50px 50px 0',
      '&:hover': {
        backgroundColor: '#444',
        color: '#F4EDE4',
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isSelected ? '#1F1F1F' : state.isFocused ? '#F4EDE4' : '#FFFFFF',
      color: state.isSelected ? '#F4EDE4' : '#1F1F1F',
      '&:active': {
        backgroundColor: '#1F1F1F',
        color: '#F4EDE4',
      },
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      borderRadius: '10px',
      minWidth: '180px',
      maxWidth: '250px',  // Adjust this value as needed
      right: 0, // Align the right edge of the menu with the right edge of the control
      left: 'auto', // Override default left alignment
    }),
  };

  const segmentedControlStyles = {
    container: {
      display: 'flex',
      backgroundColor: 'transparent',
      padding: '0',
      marginBottom: '20px',
      border: 'none',
      gap: '32px',
    },
    segment: {
      padding: '8px 0',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'transparent',
      fontSize: '16px',
      color: '#666',
      fontWeight: '400',
      position: 'relative',
      transition: 'all 0.2s ease',
    },
    activeSegment: {
      color: '#1F1F1F',
      fontWeight: '600',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '2px',
        backgroundColor: '#1F1F1F',
      }
    }
  };

  const formatDuration = (duration) => {
    if (!duration) return '';
    // Convert duration to number if it's a string
    const minutes = typeof duration === 'string' ? parseInt(duration, 10) : duration;
    if (isNaN(minutes)) return '';
    
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours === 0) return `${remainingMinutes}min`;
    if (remainingMinutes === 0) return `${hours}h`;
    return `${hours}h ${remainingMinutes}min`;
  };

  const renderCard = (item) => {
    const handleEdit = (e) => {
      e.stopPropagation();
      navigate(`/catalog/${item.id}`);
    };

    switch(item.inventoryType) {
      case 'product':
        return (
          <div className="catalog-card product-card">
            <div className="catalog-card-edit" onClick={handleEdit}>
              Ver más
            </div>
            <h5 className="catalog-card-title">{item.class_name}</h5>
            <p className="catalog-card-price">${item.cost} mxn</p>
            <div className="catalog-card-tags">
              {item.tags && item.tags.map((tag, index) => (
                <span key={index} className="catalog-card-tag">{tag}</span>
              ))}
            </div>
          </div>
        );
      
      case 'subscription':
        return (
          <div className="catalog-card subscription-card">
            <div className="catalog-card-edit" onClick={handleEdit}>
              Ver más
            </div>
            <h5 className="catalog-card-title">{item.class_name}</h5>
            <p className="catalog-card-details">
              {item.includedEvents} eventos - ${item.cost} mxn
            </p>
            <div className="catalog-card-tags">
              {item.cadence && (
                <span className={`catalog-card-tag ${getCadenceClass(item.cadence)}`}>
                  {item.cadence}
                </span>
              )}
              {item.tags && item.tags.map((tag, index) => (
                <span key={index} className="catalog-card-tag">{tag}</span>
              ))}
            </div>
          </div>
        );
      
      default:
        // Updated service card layout
        return (
          <div className="catalog-card service-card">
            <div className="catalog-card-edit" onClick={handleEdit}>
              Ver más
            </div>
            <h5 className="catalog-card-title">{item.class_name}</h5>
            <p className="catalog-card-details">
              {formatDuration(item.duration)} - ${item.cost} mxn
            </p>
            <div className="catalog-card-tags">
              {item.tags && item.tags.map((tag, index) => (
                <span key={index} className="catalog-card-tag">{tag}</span>
              ))}
            </div>
          </div>
        );
    }
  };

  const getSegmentTitle = (segment) => {
    switch(segment) {
      case 'service':
        return 'Servicios';
      case 'subscription':
        return 'Suscripciones';
      case 'product':
        return 'Productos';
      default:
        return 'items';
    }
  };

  const handleApplyFilters = () => {
    setShowFilterModal(false);
    // Any additional filter logic you need
  };

  const handleSortChange = (selectedOption) => {
    setSortOption(selectedOption.value);
  };

  const getSortedClasses = (classes) => {
    switch (sortOption) {
      case 'price_asc':
        return [...classes].sort((a, b) => a.price - b.price);
      case 'price_desc':
        return [...classes].sort((a, b) => b.price - a.price);
      case 'oldest':
        return [...classes].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      case 'recent':
      default:
        return [...classes].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
  };

  const sortedAndFilteredClasses = getSortedClasses(filteredClasses);

  return (
    <div>
      <div className="segmented-control">
        {[
          { id: 'service', label: 'Servicios' },
          { id: 'subscription', label: 'Suscripciones' },
          { id: 'product', label: 'Productos' }
        ].map(segment => (
          <button
            key={segment.id}
            className={`segment-button ${activeSegment === segment.id ? 'active' : ''}`}
            onClick={() => setActiveSegment(segment.id)}
          >
            {segment.label}
          </button>
        ))}
      </div>

      {sortedAndFilteredClasses.length > 0 ? (
        <>
          <div className="section-header">
            <h3 className='section-title'>{sortedAndFilteredClasses.length} {getSegmentTitle(activeSegment)}</h3>
            <button 
              className={`button-filter ${selectedTags.length > 0 ? 'active-filter' : ''}`}
              onClick={() => setShowFilterModal(true)}
            >
              <img src={filterIcon} alt="Filter" className="filter-icon" />
              <span className="filter-text">Filtros</span>
            </button>
          </div>

          <div className="row">
            {sortedAndFilteredClasses.map((item, index) => (
              <div key={index} className="col-sm-4">
                {renderCard(item)}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="empty-state-container">
          <img src={emptyStateIcon} alt="No products" className="empty-state-icon" />
          <h2>Aún no tienes productos</h2>
          <p>¡Manos a la obra! Crea un catálogo de productos y ofrécelo a tus clientes.</p>
          <button className="add-product-button" onClick={() => handleShowModal(null)}>
            + Agregar
          </button>
        </div>
      )}

      <AddClassModal
        show={showModal}
        handleClose={handleCloseModal}
        saveClass={saveClass}
        deleteClass={deleteClasses} 
        initialData={selectedClass}
      />

      {classes.length > 0 && (
        <div className="fab-container">
          <button className="fab" onClick={() => handleShowModal(null)}>+</button>
        </div>
      )}

      <FilterModal
        show={showFilterModal}
        handleClose={() => setShowFilterModal(false)}
        allTags={allTags}
        selectedTags={selectedTags}
        onTagChange={handleTagChange}
        onSortChange={handleSortChange}
        onApplyFilters={handleApplyFilters}
      />
    </div>
  );
};

export default CatalogList;