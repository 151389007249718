import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import './styles/BaseModal.css';
import './styles/AddClassModal.css';


const AddClassModal = ({ show, handleClose, saveClass, deleteClass, initialData }) => {
  const [className, setClassName] = useState('');
  const [cadence, setCadence] = useState('');
  const [cost, setCost] = useState('');
  const [displayCost, setDisplayCost] = useState(''); // holds the formatted display value
  const [tags, setTags] = useState('');
  const [cadenceType, setCadenceType] = useState('');
  const [inventoryType, setInventoryType] = useState('service');
  const [duration, setDuration] = useState(0);
  const [isOnlineReservation, setIsOnlineReservation] = useState(false);
  const [requiresAdvancePayment, setRequiresAdvancePayment] = useState(false);
  const [includedEvents, setIncludedEvents] = useState(1);

//INITIALIZE DATA
  useEffect(() => {
    if (show) {
      if (initialData) {
        setClassName(initialData.class_name || '');
        setCadence(initialData.cadence || '');
        setCost(initialData.cost || '');
        setDisplayCost(formatCurrency(initialData.cost));
        setTags(initialData.tags ? initialData.tags.join(', ') : '');
        setCadenceType(initialData.cadenceType || '');
        setInventoryType(initialData.inventoryType || 'service');
        setDuration(initialData.duration || 0);
        setIsOnlineReservation(initialData.isOnlineReservation || false);
        setRequiresAdvancePayment(initialData.requiresAdvancePayment || false);
        setIncludedEvents(initialData.includedEvents || 1);
      } else {
        setClassName('');
        setCadence('');
        setCost('');
        setDisplayCost('');
        setTags('');
        setCadenceType('');
        setInventoryType('service');
        setDuration(0);
        setIsOnlineReservation(false);
        setRequiresAdvancePayment(false);
        setIncludedEvents(1);
      }
    }
  }, [show, initialData]);


  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleCostChange = (e) => {
    // Remove any non-numeric characters except decimal point
    const value = e.target.value.replace(/[^\d.]/g, '');
    
    // Ensure only one decimal point
    const parts = value.split('.');
    if (parts.length > 2) return;
    
    // Update the display value
    setDisplayCost(value);
    
    // Update the actual cost value (if needed)
    setCost(parseFloat(value) || 0);
  };

//CREATE OR UPDATE CLASS
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      class_name: className,
      inventoryType: inventoryType,
      cost: cost,
      tags: tags ? tags.split(',').map(tag => tag.trim()).filter(tag => tag !== '') : [],
    };

    if (inventoryType === 'subscription') {
      Object.assign(dataToSubmit, {
        cadence: cadence,
        includedEvents: parseInt(includedEvents, 10)
      });
    } else if (inventoryType === 'service') {
      Object.assign(dataToSubmit, {
        duration: parseInt(duration),
        isOnlineReservation: isOnlineReservation,
        requiresAdvancePayment: requiresAdvancePayment,
      });
    }

    if (initialData && initialData.id) {
      dataToSubmit.id = initialData.id;
    }

    console.log('Submitting class data:', dataToSubmit);
    saveClass(dataToSubmit);
    handleClose();
  };

  // DELETE MEMNBER
  const handleDelete = () => {
    if (initialData.id) {
        deleteClass(initialData.id);
        handleClose();
    }
  };

  const handleCadenceChange = (e) => {
    const value = e.target.value;
    setCadence(value);
    setCadenceType(value === 'Mensual' ? 'recurring' : 'one-time');
  };

  const generateDurationOptions = () => {
    const options = [];
    for (let minutes = 15; minutes <= 120; minutes += 15) {
      let label;
      if (minutes < 60) {
        label = `${minutes} minutos`;
      } else if (minutes === 60) {
        label = '1 hora';
      } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        label = remainingMinutes === 0 
          ? `${hours} horas` 
          : `${hours} hora ${remainingMinutes} minutos`;
      }
      
      options.push({
        value: minutes,
        label: label
      });
    }
    return options;
  };

  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      className="base-modal inventory-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Crear nuevo inventario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="form-group">
            <Form.Label>Tipo</Form.Label>
            <Form.Select
              value={inventoryType}
              onChange={e => setInventoryType(e.target.value)}
              required
            >
              <option value="service">Servicio</option>
              <option value="product">Producto</option>
              <option value="subscription">Suscripción</option>
            </Form.Select>
          </Form.Group>

          {/* Service Type */}
          {inventoryType === 'service' && (
            <div className="service-details">
              <div className="details-header">DETALLES DEL SERVICIO ÚNICO</div>
              
              <Form.Group className="form-group">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  type="text"
                  value={className}
                  onChange={e => setClassName(e.target.value)}
                  required
                  placeholder="Ingrese el título del servicio"
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Duración</Form.Label>
                <Form.Select
                  value={duration}
                  onChange={e => setDuration(e.target.value)}
                  required
                >
                  <option value="">Selecciona duración...</option>
                  {generateDurationOptions().map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Costo</Form.Label>
                <div className="cost-input-wrapper">
                  <span className="currency-prefix">$</span>
                  <Form.Control
                    type="text"
                    value={displayCost}
                    onChange={handleCostChange}
                    required
                  />
                  <span className="currency-suffix">MXN</span>
                </div>
              </Form.Group>

              <Form.Group className="form-group">
                <div className="form-switch">
                  <Form.Label>Disponible para reservar en línea</Form.Label>
                  <Form.Check
                    type="switch"
                    id="online-reservation"
                    checked={isOnlineReservation}
                    onChange={e => setIsOnlineReservation(e.target.checked)}
                  />
                </div>
              </Form.Group>

              <Form.Group className="form-group">
                <div className="form-switch">
                  <Form.Label>Requiere pago por adelantado</Form.Label>
                  <Form.Check
                    type="switch"
                    id="advance-payment"
                    checked={requiresAdvancePayment}
                    onChange={e => setRequiresAdvancePayment(e.target.checked)}
                  />
                </div>
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Etiquetas (separadas por comas)</Form.Label>
                <Form.Control
                  type="text"
                  value={tags}
                  onChange={e => setTags(e.target.value)}
                  placeholder="ej: yoga, principiantes, mañana"
                />
              </Form.Group>
            </div>
          )}

          {/* Product Type */}
          {inventoryType === 'product' && (
            <div className="service-details">
              <h6 className="details-header">DETALLES DEL PRODUCTO</h6>
              
              <Form.Group className="mb-4">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  type="text"
                  value={className}
                  onChange={e => setClassName(e.target.value)}
                  required
                  className="rounded-input"
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Costo</Form.Label>
                <div className="cost-input-wrapper">
                  <span className="currency-prefix">$</span>
                  <Form.Control
                    type="text"
                    value={displayCost}
                    onChange={handleCostChange}
                    required
                    className="rounded-input cost-input"
                  />
                  <span className="currency-suffix">MXN</span>
                </div>
              </Form.Group>

              <Form.Group className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>Requiere pago por adelantado</Form.Label>
                  <Form.Check
                    type="switch"
                    id="advance-payment"
                    checked={requiresAdvancePayment}
                    onChange={e => setRequiresAdvancePayment(e.target.checked)}
                    className="custom-switch"
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Etiquetas (separadas por comas)</Form.Label>
                <Form.Control
                  type="text"
                  value={tags}
                  onChange={e => setTags(e.target.value)}
                  placeholder="ej: yoga, principiantes, mañana"
                  className="rounded-input"
                />
              </Form.Group>
            </div>
          )}

          {/* Subscription Type */}
          {inventoryType === 'subscription' && (
            <div className="service-details">
              <h6 className="details-header">DETALLES DE LA SUSCRIPCIÓN</h6>
              
              <Form.Group className="mb-4">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  type="text"
                  value={className}
                  onChange={e => setClassName(e.target.value)}
                  required
                  className="rounded-input"
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Costo</Form.Label>
                <div className="cost-input-wrapper">
                  <span className="currency-prefix">$</span>
                  <Form.Control
                    type="text"
                    value={displayCost}
                    onChange={handleCostChange}
                    required
                    className="rounded-input cost-input"
                  />
                  <span className="currency-suffix">MXN</span>
                </div>
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Cantidad de eventos incluidos</Form.Label>
                <div className="number-input-wrapper">
                  <Button 
                    className="number-control" 
                    onClick={() => setIncludedEvents(prev => Math.max(1, prev - 1))}
                  >
                    −
                  </Button>
                  <Form.Control
                    type="text"
                    value={includedEvents}
                    onChange={e => {
                      const value = parseInt(e.target.value) || 1;
                      setIncludedEvents(value);
                    }}
                    className="rounded-input number-input"
                  />
                  <Button 
                    className="number-control" 
                    onClick={() => setIncludedEvents(prev => prev + 1)}
                  >
                    +
                  </Button>
                </div>
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Cadencia de cobro</Form.Label>
                <Form.Control
                  as="select"
                  value={cadence}
                  onChange={e => setCadence(e.target.value)}
                  required
                  className="rounded-input"
                >
                  <option value="">Selecciona cadencia...</option>
                  <option value="Semanal">Semanal</option>
                  <option value="Mensual">Mensual</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Etiquetas (separadas por comas)</Form.Label>
                <Form.Control
                  type="text"
                  value={tags}
                  onChange={e => setTags(e.target.value)}
                  placeholder="ej: yoga, principiantes, mañana"
                  className="rounded-input"
                />
              </Form.Group>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-primary-btn" onClick={handleSubmit}>
          Crear
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddClassModal; 
