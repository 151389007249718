import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container, Tab, Tabs } from 'react-bootstrap';
import { doc, getDoc, updateDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import { useAuth } from '../contexts/AuthContexts';
import '../styles/ClientDetail.css';
import AddContactModal from '../AddContactModal';

const TAX_REGIME_DESCRIPTIONS = {
  '601': 'General de Ley Personas Morales',
  '603': 'Personas Morales con Fines no Lucrativos',
  '605': 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
  '606': 'Arrendamiento',
  '607': 'Régimen de Enajenación o Adquisición de Bienes',
  '608': 'Demás ingresos',
  '609': 'Consolidación',
  '610': 'Residentes en el Extranjero sin Establecimiento Permanente en México',
  '611': 'Ingresos por Dividendos (socios y accionistas)',
  '612': 'Personas Físicas con Actividades Empresariales y Profesionales',
  '614': 'Ingresos por intereses',
  '615': 'Régimen de los ingresos por obtención de premios',
  '616': 'Sin obligaciones fiscales',
  '620': 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
  '621': 'Incorporación Fiscal',
  '622': 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
  '623': 'Opcional para Grupos de Sociedades',
  '624': 'Coordinados',
  '625': 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
  '626': 'Régimen Simplificado de Confianza'
};

const CFDI_USE_DESCRIPTIONS = {
  'G03': 'Gastos en general',
  'D01': 'Honorarios médicos, dentales y gastos hospitalarios',
  'P01': 'Por definir',
  'CN01': 'Nómina',
  'I01': 'Construcciones',
  'I02': 'Mobiliario y equipo de oficina por inversiones',
  'I03': 'Equipo de transporte',
  'I04': 'Equipo de computo y accesorios',
  'I05': 'Dados, troqueles, moldes, matrices y herramental',
  'I08': 'Otra maquinaria y equipo'
};

const ClientDetail = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const [showEditModal, setShowEditModal] = useState(false);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        if (!currentUser) {
          navigate('/login');
          return;
        }

        const clientDoc = await getDoc(doc(db, 'members', clientId));
        
        if (!clientDoc.exists()) {
          setError('Client not found');
          return;
        }

        const clientData = clientDoc.data();
        
        if (clientData.createdBy !== currentUser.uid) {
          setError('Unauthorized access');
          return;
        }

        setClient({
          id: clientDoc.id,
          ...clientData
        });
      } catch (error) {
        console.error('Error fetching client:', error);
        setError('Error loading client details');
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [clientId, navigate, currentUser]);

  useEffect(() => {
    const fetchPayments = async () => {
      if (!client) return;
      
      try {
        const paymentsQuery = query(
          collection(db, 'payments'),
          where('metadata.memberId', '==', client.id)
        );
        
        const querySnapshot = await getDocs(paymentsQuery);
        const paymentsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setPayments(paymentsData);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchPayments();
  }, [client]);

  const handleEditSave = async (updatedData) => {
    try {
      const docRef = doc(db, 'members', clientId);
      await updateDoc(docRef, updatedData);
      
      setClient({
        id: clientId,
        ...updatedData
      });
      
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating client:', error);
      setError('Error updating client details');
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await deleteDoc(doc(db, 'members', clientId));
      navigate(-1);
    } catch (error) {
      console.error('Error deleting client:', error);
      setError('Error deleting client');
    }
  };

  const handleCopy = (text) => {
    if (!text || text === 'No especificado') return;
    
    navigator.clipboard.writeText(text)
      .then(() => {
        // Optional: You could add a toast notification here
        console.log('Copied to clipboard:', text);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;
  if (!client) return <div>Cliente no encontrado</div>;

  return (
    <div className="client-detail-page">
      <Container className="client-detail-container">
        <Button variant="link" onClick={() => navigate(-1)} className="back-button">
          Volver
        </Button>

        <div className="client-header">
          <h1>{client.contact_name}</h1>
        </div>

        <Tabs 
          defaultActiveKey="personal" 
          className="segment-tabs"
          variant="tabs"
          fill={false}
          justify={false}
          tabClassName="segment-link"
        >
          <Tab eventKey="personal" title="Personal">
            <div className="tab-content-section">
              <div className="info-section">
                <label>TELÉFONO (WHATSAPP)</label>
                <a href={`https://wa.me/${client.number}`}>{client.number}</a>
              </div>

              <div className="info-section">
                <label>EMAIL</label>
                <span>{client.email}</span>
              </div>

              <div className="info-section">
                <label>SUSCRIPCIONES ACTIVAS</label>
                <div className="subscriptions-container">
                  {client.activeSuscriptions?.length > 0 ? (
                    client.activeSuscriptions.map((sub, index) => (
                      <span key={index} className="subscription-pill">
                        {sub.className}
                      </span>
                    ))
                  ) : (
                    <span className="no-subscriptions">No hay suscripciones activas</span>
                  )}
                </div>
              </div>

              <div className="info-section">
                <label>ETIQUETAS</label>
                <div className="tags-container">
                  {client.tags?.length > 0 ? (
                    client.tags.map((tag, index) => (
                      <span key={index} className="client-tag">{tag}</span>
                    ))
                  ) : (
                    <span className="no-tags">Sin etiquetas</span>
                  )}
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="payments" title="Pagos">
            <div className="tab-content-section">
              {payments.map((payment) => (
                <div key={payment.id} className="payment-item">
                  {payment.itemsPaid?.map((item, index) => (
                    <div key={index} className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <h6>{item.name || 'Sin nombre'}</h6>
                        <small className="text-muted">
                          {new Date(payment.date).toLocaleDateString('es-MX')} • {payment.paymentMethod}
                        </small>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="me-3">${item.cost || 0}</span>
                        <span className={`status-pill ${(payment.status || 'pending').toLowerCase()}`}>
                          {payment.status || 'Pending'}
                        </span>
                      </div>
                    </div>
                  ))}
                  {!payment.itemsPaid?.length && (
                    <div className="text-center text-muted py-2">
                      No hay detalles disponibles para este pago
                    </div>
                  )}
                </div>
              ))}
              {payments.length === 0 && (
                <div className="text-center text-muted py-4">
                  No hay pagos registrados
                </div>
              )}
            </div>
          </Tab>
          <Tab eventKey="billing" title="Facturación">
            <div className="tab-content-section">
              <div className="info-section">
                <label>NOMBRE COMPLETO, DENOMINACIÓN O RAZÓN SOCIAL</label>
                <div className="value-with-copy">
                  <span>{client.invoiceName || 'No especificado'}</span>
                  {client.invoiceName && (
                    <button 
                      className="copy-button" 
                      onClick={() => handleCopy(client.invoiceName)}
                      aria-label="Copiar"
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#1f1f1f" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                  )}
                </div>
              </div>

              <div className="info-section">
                <label>RFC</label>
                <div className="value-with-copy">
                  <span>{client.rfc || 'No especificado'}</span>
                  {client.rfc && (
                    <button 
                      className="copy-button" 
                      onClick={() => handleCopy(client.rfc)}
                      aria-label="Copiar"
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#1f1f1f" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                  )}
                </div>
              </div>

              <div className="info-section">
                <label>CÓDIGO POSTAL DEL DOMICILIO FISCAL</label>
                <div className="value-with-copy">
                  <span>{client.fiscalAddress || 'No especificado'}</span>
                  <button className="copy-button" aria-label="Copiar">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#1f1f1f" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>

              <div className="info-section">
                <label>REGIMEN FISCAL</label>
                <span>
                  {client.taxRegime ? (
                    `${client.taxRegime} - ${TAX_REGIME_DESCRIPTIONS[client.taxRegime] || 'Descripción no disponible'}`
                  ) : (
                    'No especificado'
                  )}
                </span>
              </div>

              <div className="info-section">
                <label>USO FISCAL</label>
                <div className="value-with-copy">
                  <span>
                    {client.cfdiUse ? (
                      `${client.cfdiUse} - ${CFDI_USE_DESCRIPTIONS[client.cfdiUse] || 'Descripción no disponible'}`
                    ) : (
                      'No especificado'
                    )}
                  </span>
                  {client.cfdiUse && (
                    <button 
                      className="copy-button" 
                      onClick={() => handleCopy(`${client.cfdiUse} - ${CFDI_USE_DESCRIPTIONS[client.cfdiUse]}`)}
                      aria-label="Copiar"
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#1f1f1f" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>

        <div className="action-buttons">
          <Button 
            variant="outline-danger" 
            onClick={() => handleDeleteClient(client.id)}
          >
            Eliminar
          </Button>
          <Button 
            variant="dark"
            onClick={() => setShowEditModal(true)}
          >
            Editar
          </Button>
        </div>

        <AddContactModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          saveMember={handleEditSave}
          deleteMember={handleDeleteClient}
          member={client}
        />
      </Container>
    </div>
  );
};

export default ClientDetail; 